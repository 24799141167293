.export {
  padding: 5px;
  margin-top: 15px;
}
.export::before{
  margin-right: 5px;
}
.clsCreateEvent{
  float: right;
}
form input.error,
form textarea.error {
  border-color: red;
}
.headerTitle{
  margin-top: 12px;
}
.cardHeader{
  color: black !IMPORTANT;
  font-weight: 500;
  background-color: #bababafc !important
}
.eventListTable .-header{
  background-color: rgba(186, 186, 186, 0.99);
  color: black;
  font-weight: 400;
}
.eventListTable .-header .rt-th{
  border-right: 1px solid #6b676773 !important;
}
.formButtons{
  text-align: end;
}
.formSubButton{
  right: 5px;
}
.cardHeaderIcon{
  margin-right: 5px;
}
.eventdetSpan{
  font-weight: 500;
}
.createImgCont{
  position: relative;
  margin: 2px;
  border: 1px solid #ebdede;
}
.imgBtn{
  position: absolute;
  text-align: end;
  right: 3%;
  top: 3%;
  cursor: pointer;
}