// Here you can add other styles
.MuiTableHead-root th {
    font-size: xx-small;
    font-weight: bolder;
}
.MuiTableRow-root td {
    font-size: xx-small !important;
    font-weight: 400 !important;
}
.clsUserActionIcon:hover {
    cursor: pointer;
}
.userActionBtnGrp button {
    justify-content: left;
}
.loginLngSwitch {
    margin-top: -2rem;
    margin-bottom: -2rem;
}